import React, { Component } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import BackgroundImage from "gatsby-background-image"
import SEO from '../components/seo'

import indexStyles from "./indexStyles.module.scss"


class IndexPage extends Component {


   render() {
      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const bgImageData = this.props.data.desktop.childImageSharp.fluid

      const footerContent = {
         C2As: [{ text: "", link: "" }],
         styles: {
            backgroundColor: "transparent",
         },
      }

      return (
         <BackgroundImage
            Tag="div"
            className={"mainContainer backgroundImage"}
            fluid={bgImageData}
            backgroundColor={"#ffffff"}
         >
         
         <SEO title="Mr BJ Jackson Homepage" />
            <Layout
               title="Mr BJ Jackson"
               superTitle="Hello, I'm"
               pageStyles={indexStyles}
               animationStatus={animationStatus}
               footerContent={footerContent}
            >
               <h4 className={`${indexStyles.introduction} subheader`}>
                  I’m a music producer, mixer and sound designer based in
                  Cornwall and London
               </h4>
            </Layout>
         </BackgroundImage>
      )
   }
}

export default IndexPage

export const data = graphql`
   query {
      desktop: file(relativePath: { eq: "bjontheprodo-home-bg.jpg" }) {
         childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`
